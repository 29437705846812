import React, { useMemo, useRef, useEffect, useState } from "react";
import { Canvas, useFrame, useThree, useLoader } from "@react-three/fiber";
import * as THREE from "three";
import { gsap } from "gsap";
import { OrbitControls, Text, useTexture } from "@react-three/drei";
import font from "../HankenGrotesk-Bold.woff"; // Ensure the path to the font is correct
import { BufferGeometry, Float32BufferAttribute, BufferAttribute, Points, PointsMaterial } from 'three';

const characters =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()";

const TypeShuffle: React.FC<{ text: string }> = ({ text }) => {
  const textRef = useRef<THREE.Mesh>(null);
  const [displayText, setDisplayText] = useState(text);

  useEffect(() => {
    const interval = setInterval(() => {
      setDisplayText((prevText) => {
        return prevText
          .split("")
          .map((char, index) => {
            return Math.random() < 0.1
              ? characters[Math.floor(Math.random() * characters.length)]
              : text[index];
          })
          .join("");
      });
    }, 100);

    return () => clearInterval(interval);
  }, [text]);

  return (
    <Text
      font={font}
      letterSpacing={0.0025}
      ref={textRef}
      position={[0, 0, 0]}
      fontSize={3}
      color="white"
      anchorX="center"
      anchorY="middle"
    >
      {displayText}
    </Text>
  );
};

const Marquee: React.FC = () => {
  const textRefs = useRef<THREE.Mesh[]>([]);
  const speed = 0.055; // Speed of the marquee
  const totalTexts = 10; // Number of text instances
  const spacing = 15; // Space between texts

  useFrame(() => {
    textRefs.current.forEach((textRef) => {
      if (textRef) {
        textRef.position.x -= speed; // Move the text to the left
        if (textRef.position.x < -spacing) {
          // If the text has moved out of view
          textRef.position.x = spacing * (totalTexts - 1); // Reset its position
        }
      }
    });
  });

  const texts = useMemo(() => {
    return Array.from({ length: totalTexts }, (_, index) => (
      <Text
        key={index}
        ref={(ref) => ref && textRefs.current.push(ref)}
        position={[index * spacing, 0, 0]} // Adjusted position for proper spacing
        fontSize={2}
        font={font}
        color="white"
        anchorX="center"
        anchorY="middle"
      >
        SZN LABS
      </Text>
    ));
  }, []);

  return <>{texts}</>;
};

const RAIN_COUNT = 30000; // Increased rain particles for a heavier storm
const CLOUD_COUNT = 25;
const SCENE_SIZE = 400; // Adjusted size for more dramatic storm effect
const TORNADO_SWIRL_SPEED = 0.005; // Speed of tornado rotation

const ParticleTornado: React.FC = () => {
  const meshRef = useRef<THREE.InstancedMesh>(null);
  const tempObject = useMemo(() => new THREE.Object3D(), []);
  const particleCount = 20000; // Increased particle count for better visibility
  const tornadoHeight = 100;
  const tornadoRadius = 10; // Increased radius for more visual impact

  const particles = useMemo(() => {
    const temp = [];
    for (let i = 0; i < particleCount; i++) {
      const t = Math.random();
      const angle = t * Math.PI * 40;
      const radius = 0.5 + (1 - t) * tornadoRadius;
      const x = Math.cos(angle) * radius;
      const y = t * tornadoHeight;
      const z = Math.sin(angle) * radius;
      temp.push({ x, y, z, t });
    }
    return temp;
  }, [particleCount]);

  useFrame((state) => {
    const mesh = meshRef.current as THREE.InstancedMesh | null;
    if (mesh) {
      const time = state.clock.getElapsedTime();
      const scrollOffset = (time * 5) % tornadoHeight;
  
      particles.forEach((particle, i) => {
        const { x, y, z, t } = particle;
  
        tempObject.position.set(
          x * (1 + Math.sin(time * 2 + t * 10) * 0.2),
          ((y - scrollOffset + tornadoHeight) % tornadoHeight) -
            tornadoHeight / 2,
          z * (1 + Math.cos(time * 2 + t * 10) * 0.2)
        );
  
        const scale = 0.05 + Math.sin(time * 3 + t * 20) * 0.03;
        tempObject.scale.setScalar(scale);
        tempObject.rotation.y = time + t * Math.PI * 5;
  
        tempObject.updateMatrix();
        mesh.setMatrixAt(i, tempObject.matrix);
      });
      mesh.instanceMatrix.needsUpdate = true;
    }
  });
  

  return (
    <instancedMesh ref={meshRef} args={[undefined, undefined, particleCount]}>
      <sphereGeometry args={[0.3, 8, 8]} /> {/* Increased size */}
      <meshBasicMaterial color="#f4f4f4" transparent opacity={0.6} />{" "}
      {/* Bright yellow color */}
    </instancedMesh>
  );
};

const RainAndClouds: React.FC = () => {
  return (
    <Canvas
      style={{ background: "black", width: "100vw", height: "100vh" }}
      camera={{ position: [0, 0, 7], fov: 75 }}
    >
      <RainParticles />
      <Clouds />
      <Lights />
      <OrbitControls
        enableZoom={false}
        enablePan={false}
        enableRotate={false}
      />{" "}
      {/* Disable zoom for fixed view */}
      <ParticleTornado /> {/* Added the tornado before rain */}
      <Marquee />
    </Canvas>
  );
};

// Rain component with tornado effect

const RainParticles: React.FC = () => {
  const rainRef = useRef<Points>(null);
  const rainGeo = useRef<THREE.BufferGeometry>(null);

  const positions = useMemo(() => {
    const arr = new Float32Array(RAIN_COUNT * 3);
    for (let i = 0; i < RAIN_COUNT * 3; i += 3) {
      arr[i] = Math.random() * SCENE_SIZE - SCENE_SIZE / 2;     // X
      arr[i + 1] = Math.random() * SCENE_SIZE - SCENE_SIZE / 2; // Y
      arr[i + 2] = Math.random() * SCENE_SIZE - SCENE_SIZE / 2; // Z
    }
    return arr;
  }, []);

  useEffect(() => {
    if (rainGeo.current) {
      rainGeo.current.setAttribute('position', new BufferAttribute(positions, 3));
    }
  }, [positions]);

  useFrame(() => {
    if (rainRef.current && rainGeo.current) {
      const positionAttribute = rainGeo.current.getAttribute('position') as BufferAttribute;
      const array = positionAttribute.array as Float32Array;

      for (let i = 1; i < array.length; i += 3) {
        array[i] -= 0.5; // Move particles down
        if (array[i] < -SCENE_SIZE / 2) {
          array[i] = SCENE_SIZE / 2;
          array[i - 1] = Math.random() * SCENE_SIZE - SCENE_SIZE / 2; // X
          array[i + 1] = Math.random() * SCENE_SIZE - SCENE_SIZE / 2; // Z
        }
      }
      positionAttribute.needsUpdate = true;
    }
  });

  return (
    <points ref={rainRef}>
      <bufferGeometry ref={rainGeo} />
      <pointsMaterial size={0.1} transparent={true} color={0xaaaaaa} />
    </points>
  );
};




// Cloud component with faster rotation for storm effect
const Clouds: React.FC = () => {
  const cloudParticles = useRef<THREE.Mesh[]>([]);
  const cloudRefs = useRef<THREE.Group>(null);
  const cloudTexture = useLoader(THREE.TextureLoader, "./smoke-1.png");

  useEffect(() => {
    if (cloudRefs.current) {
      for (let p = 0; p < CLOUD_COUNT; p++) {
        const cloud = new THREE.Mesh(
          new THREE.PlaneGeometry(500, 500),
          new THREE.MeshLambertMaterial({
            map: cloudTexture,
            transparent: true,
            opacity: 0.6,
          })
        );
        cloud.position.set(
          Math.random() * 800 - 400,
          500,
          Math.random() * 500 - 450
        );
        cloud.rotation.x = 1.16;
        cloud.rotation.y = -0.12;
        cloud.rotation.z = Math.random() * 360;
        cloudParticles.current.push(cloud);
        cloudRefs.current?.add(cloud);
      }
    }
  }, [cloudTexture]);

  useFrame(() => {
    cloudParticles.current.forEach((cloud) => {
      cloud.rotation.z -= 0.01; // Increased rotation speed for storm effect
    });
  });

  return <group ref={cloudRefs}></group>;
};

// Lights and fog effects for the stormy atmosphere
const Lights: React.FC = () => {
  const flashRef = useRef<THREE.PointLight>(null);

  useFrame(() => {
    if (
      flashRef.current &&
      (Math.random() > 0.93 || flashRef.current.power > 100)
    ) {
      if (flashRef.current.power < 100) {
        flashRef.current.position.set(
          Math.random() * 400,
          300 + Math.random() * 200,
          100
        );
      }
      flashRef.current.power = 50 + Math.random() * 500;
    }
  });

  return (
    <>
      <ambientLight intensity={0.5} />
      <directionalLight position={[0, 0, 1]} intensity={0.5} />
      <pointLight
        ref={flashRef}
        color={0x062d89}
        intensity={30}
        distance={500}
        decay={1.7}
        position={[200, 300, 100]}
      />
      <fogExp2 attach="fog" args={[0x11111f, 0.002]} />
    </>
  );
};

export default RainAndClouds;
