import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import logo from './assets/logo.png';
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import { useState } from "react";
import {
  ChevronUp,
  Eye,
  ScanFace,
  Briefcase,
  Users,
  Book,
  TreePine,
  HelpCircle,
  Sparkles,
  Newspaper,
  HousePlus,
  Trees,
  KeyRound,
} from "lucide-react";

const FooterNavbar: React.FC = () => {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState<string | null>(null);

  const navItems = [
    {
      linkName: "Home",
      linkRoute: "/",
    },
    {
      linkName: "About",
      linkRoute: "#",
      subMenu: [
        {
          icon: <Eye />,
          name: "Manifesto",
          description: "Learn more about our goals",
          route: "/about/vision",
        },
        {
          icon: <ScanFace />,
          name: "Team",
          description: "Meet our dedicated, remote team",
          route: "/about/team",
        },
        {
          icon: <TreePine />,
          name: "Grove",
          description: "Different branches under one tree",
          route: "about/houses",
        },
      ],
    },
    {
      linkName: "Ventures ↑",
      linkRoute: "/ventures",
      subMenu: [
        {
          icon: <Briefcase />,
          name: "Ecosystem",
          description: "Explore our products",
          route: "/ventures/products",
        },
        {
          icon: <Sparkles />,
          name: "Forecasts",
          description: "Emerging trends and opportunities",
          route: "/ventures/opportunities",
        },
      ],
    },
    {
      linkName: "Careers",
      linkRoute: "/careers",
    },
    {
      linkName: "Press ✦",
      linkRoute: "/press",
    },
    {
      linkName: "Support",
      linkRoute: "/support",
      subMenu: [
        {
          icon: <KeyRound />,
          name: "Partner Login",
          description: "See the storm",
          route: "/login",
        },
        {
          icon: <Users />,
          name: "Contact Us",
          description: "Get in touch",
          route: "/support/contact",
        },
      ],
    },
  ];

  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 20px",
        borderTop: "1px solid rgba(255, 255, 255, 0.75)",
        background: "rgba(0, 0, 0, 0.85)",
        backdropFilter: "blur(5px)",
      }}
    >
      <div className="fNavbarLeft">
        <div className="fNavbarLogo">
          <img src={logo} height="12px" width="12px" alt="SZN Labs Logo" />
        </div>
        <Link
          to="/"
          className="fNavbarTitle"
          style={{ color: "white", fontWeight: "bold", textDecoration: "none" }}
        >
          SZN LABS
        </Link>
      </div>
      <div style={{ position: "relative" }}>
        {navItems.map((item, index) => (
          <div
            key={index}
            style={{ display: "inline-block", position: "relative" }}
            onMouseEnter={() => {
              setActiveMenu(item.linkName);
            }}
            onMouseLeave={() => {
              setActiveMenu(null);
            }}
          >
            <Link
              to={item.linkRoute}
              className="fNavbarLink"
              style={{
                color: "white",
                marginLeft: "20px",
                fontSize: "11px",
                textDecoration: "none",
                opacity: location.pathname === item.linkRoute ? 1 : 0.975,
                transition: "all 0.3s",
              }}
            >
              {item.linkName}
            </Link>
            {item.subMenu && activeMenu === item.linkName && (
              <div
                style={{
                  position: "absolute",
                  bottom: "100%",
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "300px",
                  background: "rgba(0, 0, 0, 0.9)",
                  borderRadius: "8px",
                  padding: "16px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  zIndex: 1000,
                }}
              >
                {item.subMenu.map((subItem, subIndex) => (
                  <Link
                    key={subIndex}
                    to={subItem.route}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "12px",
                      color: "white",
                      textDecoration: "none",
                      borderRadius: "4px",
                      transition: "background-color 0.3s",
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor =
                        "rgba(255, 255, 255, 0.1)";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = "transparent";
                    }}
                  >
                    <div style={{ marginRight: "16px", color: "#40e953" }}>
                      {subItem.icon}
                    </div>
                    <div>
                      <div
                        style={{
                          fontFamily: "monospace",
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                      >
                        {subItem.name}
                      </div>
                      <div
                        style={{
                          fontFamily: "sans-serif",
                          fontSize: "12px",
                          color: "rgba(255, 255, 255, 0.7)",
                        }}
                      >
                        {subItem.description}
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const rootElement = document.getElementById("root")!;
const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
      <HelmetProvider>
    <BrowserRouter>
      <App />

      <FooterNavbar />
    </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);
