import "../styles/404.css";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import React, { useState } from "react";
import {
  ChevronUp,
  ScanEye,
  ScanFace,
  Briefcase,
  Users,
  Book,
  HelpCircle,
} from "lucide-react";

export default function NotFound() {
  return (
    <>
      <div className="notfound-container">
        <div className="notfound-card">
          <h3>(¬_¬)</h3>
          <h1 className="notfound-number">404</h1>
          <img
            className="notfound-img"
            src="https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExYzhubjUzaWxxOXR4Ynpxd2d4bmg0ZTdzb3BpajdwYmpqbGI4aGVvbSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/Ew1hKwDKTmERD3gaWH/giphy.webp"
          />

          <p className="notfound-description">
            {" "}
            Oops... it looks like you're searching 
            <br/>
            for something that doesn't
            exist (yet).
          </p>

          <Link
            to="/"
            style={{
              fontSize: "1.6rem",
              color: "#10c635",
              textDecoration: "underline",
            }}
          >
            Return to Home
          </Link>
        </div>
      </div>
    </>
  );
}
