import "./styles.css";
import "./fonts.css";
import TornadoScene from "./Pages/TornadoScene";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import React, { useState } from "react";
import {Helmet} from "react-helmet-async";

import {
  ChevronUp,
  ScanEye,
  ScanFace,
  Briefcase,
  Users,
  Book,
  Fan,
  HelpCircle,
} from "lucide-react";

import About from "./Pages/About";
import NotFound from "./Pages/NotFound";

export const LightBulbFillIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    
  >
    <path
      fill="currentColor"
      d="M12.754 2.305a.75.75 0 0 0-1.5 0v1.48a.75.75 0 0 0 1.5 0zm5.111 7.99a5.879 5.879 0 0 0-1.11-2.22a6 6 0 0 0-1.91-1.59a6.17 6.17 0 0 0-2.38-.69a6 6 0 0 0-2.46.33a6 6 0 0 0-2.13 1.29a6.18 6.18 0 0 0-1.43 2a6 6 0 0 0-.49 2.43a6.09 6.09 0 0 0 2.41 5l.35.33c.3.31.3.31.29 1v.32a1.58 1.58 0 0 0 .1.65c.07.222.194.425.36.59c.076.078.16.148.25.21v1a1.38 1.38 0 0 0 1.26 1.5h2a1.39 1.39 0 0 0 1.27-1.5v-1a1.64 1.64 0 0 0 .25-.21c.157-.166.277-.364.35-.58a1.66 1.66 0 0 0 .1-.66v-.37c0-.55 0-.55.31-.9l.38-.35a6.17 6.17 0 0 0 2.33-4.07a5.882 5.882 0 0 0-.1-2.51m-5.07 10.63h-1.58v-.63h1.58zm-.79-10.56a1.23 1.23 0 0 0-1.23 1.23a1 1 0 1 1-2 0a3.21 3.21 0 0 1 3.23-3.23a1 1 0 0 1 0 2m9.16 2.5h-1.83a.75.75 0 0 1 0-1.5h1.83a.75.75 0 1 1 0 1.5m-3.68-6.01a.74.74 0 0 1-.53-.22a.75.75 0 0 1 0-1.06l1.3-1.3a.75.75 0 0 1 1.06 1.06l-1.3 1.3a.73.73 0 0 1-.53.22m2.3 12.44a.79.79 0 0 1-.53-.22l-1.3-1.3a.75.75 0 0 1 .242-1.226a.74.74 0 0 1 .818.166l1.3 1.3a.74.74 0 0 1 0 1.06a.75.75 0 0 1-.53.22M6.535 6.855a.75.75 0 0 1-.53-.22l-1.3-1.3a.753.753 0 1 1 1.07-1.06l1.29 1.3a.75.75 0 0 1-.53 1.28m-2.29 12.44a.71.71 0 0 1-.53-.22a.75.75 0 0 1 0-1.06l1.3-1.3a.75.75 0 0 1 1.06 1.06l-1.29 1.31a.79.79 0 0 1-.54.21m.42-6.43h-1.83a.75.75 0 1 1 0-1.5h1.83a.75.75 0 1 1 0 1.5"
    ></path>
  </svg>
);

export const OutlineInsightsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    
  >
    <path
      fill="currentColor"
      d="M21 8c-1.45 0-2.26 1.44-1.93 2.51l-3.55 3.56c-.3-.09-.74-.09-1.04 0l-2.55-2.55C12.27 10.45 11.46 9 10 9c-1.45 0-2.27 1.44-1.93 2.52l-4.56 4.55C2.44 15.74 1 16.55 1 18c0 1.1.9 2 2 2c1.45 0 2.26-1.44 1.93-2.51l4.55-4.56c.3.09.74.09 1.04 0l2.55 2.55C12.73 16.55 13.54 18 15 18c1.45 0 2.27-1.44 1.93-2.52l3.56-3.55c1.07.33 2.51-.48 2.51-1.93c0-1.1-.9-2-2-2z"
    ></path>
    <path
      fill="currentColor"
      d="m15 9l.94-2.07L18 6l-2.06-.93L15 3l-.92 2.07L12 6l2.08.93zM3.5 11L4 9l2-.5L4 8l-.5-2L3 8l-2 .5L3 9z"
    ></path>
  </svg>
);

export const PaletteLtrIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    
  >
    <path
      fill="currentColor"
      d="M13 15v2a3 3 0 0 1-3 3a10 10 0 1 1 10-10a5 5 0 0 1-5 5ZM3 8.5a1.5 1.5 0 1 0 3 0a1.5 1.5 0 1 0-3 0m3-4a1.5 1.5 0 1 0 3 0a1.5 1.5 0 1 0-3 0m5 0a1.5 1.5 0 1 0 3 0a1.5 1.5 0 1 0-3 0m3 4a1.5 1.5 0 1 0 3 0a1.5 1.5 0 1 0-3 0"
    ></path>
  </svg>
);

export const BrainIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 48 48"
    
  >
    <mask id="ipSBrain0">
      <g fill="none" stroke="#fff">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="4.667"
          d="M19.036 44c-.98-3.195-2.458-5.578-4.435-7.147c-2.965-2.353-7.676-.89-9.416-3.318c-1.74-2.428 1.219-6.892 2.257-9.526c1.039-2.634-3.98-3.565-3.394-4.313c.39-.499 2.927-1.937 7.609-4.316C12.987 7.794 17.9 4 26.398 4C39.144 4 44 14.806 44 21.68c0 6.872-5.88 14.276-14.256 15.873c-.749 1.09.331 3.24 3.24 6.447"
        ></path>
        <path
          fill="#fff"
          fillRule="evenodd"
          strokeLinejoin="round"
          strokeWidth="4"
          d="M19.5 14.5c-.654 2.534-.46 4.314.583 5.339c1.042 1.024 2.818 1.695 5.328 2.01c-.57 3.269.125 4.802 2.083 4.6c1.958-.201 3.135-1.015 3.53-2.44c3.06.86 4.719.14 4.976-2.16c.385-3.45-1.475-6.201-2.238-6.201c-.762 0-2.738-.093-2.738-1.148s-2.308-1.65-4.391-1.65s-.83-1.405-3.69-.85c-1.907.37-3.055 1.203-3.443 2.5Z"
          clipRule="evenodd"
        ></path>
        <path
          strokeLinecap="round"
          strokeWidth="4"
          d="M30.5 25.5c-1.017.631-2.412 1.68-3 2.5c-1.469 2.05-2.66 3.298-2.92 4.608"
        ></path>
      </g>
    </mask>
    <path fill="currentColor" d="M0 0h48v48H0z" mask="url(#ipSBrain0)"></path>
  </svg>
);

export const ChildIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 384 512"
    
  >
    <path
      fill="currentColor"
      d="M120 72c0-39.765 32.235-72 72-72s72 32.235 72 72c0 39.764-32.235 72-72 72s-72-32.236-72-72zm254.627 1.373c-12.496-12.497-32.758-12.497-45.254 0L242.745 160H141.254L54.627 73.373c-12.496-12.497-32.758-12.497-45.254 0c-12.497 12.497-12.497 32.758 0 45.255L104 213.254V480c0 17.673 14.327 32 32 32h16c17.673 0 32-14.327 32-32V368h16v112c0 17.673 14.327 32 32 32h16c17.673 0 32-14.327 32-32V213.254l94.627-94.627c12.497-12.497 12.497-32.757 0-45.254z"
    ></path>
  </svg>
);

export const SharpTouchAppIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    
  >
    <path
      fill="currentColor"
      d="M8.25 9.24V5.5a2.5 2.5 0 0 1 5 0v3.74c1.21-.81 2-2.18 2-3.74c0-2.49-2.01-4.5-4.5-4.5s-4.5 2.01-4.5 4.5c0 1.56.79 2.93 2 3.74zm5.08 2.26h-1.08v-6c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v10.74l-4.04-.85L4 16.62L9.38 22h8.67l1.07-7.62l-5.79-2.88z"
    ></path>
  </svg>
);

export const BankIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 1025 1025"
    
  >
    <path
      fill="currentColor"
      d="M960.654 257h-896q-27 0-45.5-19t-18.5-45.5t18.5-45t45.5-18.5l448-129l448 129q27 0 45.5 18.5t18.5 45t-18.5 45.5t-45.5 19zm-832 576q-27 0-45.5-19t-18.5-45V385q0-27 18.5-45.5t45.5-18.5h128q27 0 45.5 18.5t18.5 45.5v384q0 26-19 45t-45 19h-128zm320 0q-27 0-45.5-19t-18.5-45V385q0-27 19-45.5t45-18.5h128q27 0 45.5 18.5t18.5 45.5v384q0 26-18.5 45t-45.5 19h-128zm320 0q-26 0-45-19t-19-45V385q0-27 19-45.5t45-18.5h128q27 0 45.5 18.5t18.5 45.5v384q0 26-18.5 45t-45.5 19h-128zm-704 64h896q27 0 45.5 18.5t18.5 45t-18.5 45.5t-45.5 19h-896q-27 0-45.5-19t-18.5-45.5t18.5-45t45.5-18.5z"
    ></path>
  </svg>
);

export const AppleShortcutsSolidIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    
  >
    <g fill="currentColor" fillRule="evenodd" clipRule="evenodd">
      <path d="M3.249 11.32c-1.698-1.08-1.698-3.56 0-4.64l6.2-3.946a4.75 4.75 0 0 1 5.101 0l6.201 3.946c1.698 1.08 1.698 3.56 0 4.64l-6.2 3.946a4.75 4.75 0 0 1-5.101 0L3.249 11.32Z"></path>
      <path d="m19.66 11.986l-5.11-3.252a4.75 4.75 0 0 0-5.1 0l-5.11 3.252l5.915 3.765a3.25 3.25 0 0 0 3.49 0l5.916-3.765Zm1.362.889c-.085.07-.175.134-.27.195l-6.202 3.946a4.75 4.75 0 0 1-5.1 0L3.249 13.07a2.85 2.85 0 0 1-.27-.195c-1.423 1.16-1.333 3.425.27 4.445l6.2 3.946a4.75 4.75 0 0 0 5.101 0l6.201-3.946c1.603-1.02 1.693-3.285.27-4.445Z"></path>
    </g>
  </svg>
);

export const HatWizardIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 512 512"
    
  >
    <path
      fill="currentColor"
      d="m64 416l104.6-235.3c15.3-34.4 40.3-63.5 72-83.7L387.5 3c3-1.9 6.5-2.9 10-2.9C407.7 0 416 8.3 416 18.6v1.6c0 2.6-.5 5.1-1.4 7.5l-59.8 149.2c-1.9 4.7-2.8 9.7-2.8 14.7c0 5.5 1.2 11 3.4 16.1L448 416H240.9l11.8-35.4l40.4-13.5c6.5-2.2 10.9-8.3 10.9-15.2s-4.4-13-10.9-15.2l-40.4-13.5l-13.5-40.4c-2.2-6.4-8.3-10.8-15.2-10.8s-13 4.4-15.2 10.9l-13.5 40.4l-40.4 13.5c-6.5 2.2-10.9 8.3-10.9 15.2s4.4 13 10.9 15.2l40.4 13.5l11.8 35.3H64zm215.6-274.5c-1.1-3.3-4.1-5.5-7.6-5.5s-6.5 2.2-7.6 5.5l-6.7 20.2l-20.2 6.7c-3.3 1.1-5.5 4.1-5.5 7.6s2.2 6.5 5.5 7.6l20.2 6.7l6.7 20.2c1.1 3.3 4.1 5.5 7.6 5.5s6.5-2.2 7.6-5.5l6.7-20.2l20.2-6.7c3.3-1.1 5.5-4.1 5.5-7.6s-2.2-6.5-5.5-7.6l-20.2-6.7l-6.7-20.2zM32 448h448c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32z"
    ></path>
  </svg>
);
export const ChartUpFillIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    
  >
    <path
      fill="currentColor"
      d="M7.58 13.06v7.08A1.58 1.58 0 0 1 6 21.72H4.12a1.58 1.58 0 0 1-1.58-1.58v-7.08a1.59 1.59 0 0 1 1.58-1.59H6a1.59 1.59 0 0 1 1.58 1.59m6.94-4.59v11.7a1.59 1.59 0 0 1-1.59 1.58h-1.87a1.59 1.59 0 0 1-1.59-1.58V8.47a1.59 1.59 0 0 1 1.59-1.59h1.87a1.59 1.59 0 0 1 1.59 1.59m6.94-4.63v16.33a1.59 1.59 0 0 1-1.59 1.58H18a1.59 1.59 0 0 1-1.59-1.58V3.84A1.59 1.59 0 0 1 18 2.25h1.87a1.59 1.59 0 0 1 1.59 1.59"
    ></path>
  </svg>
);

export const BriefcaseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 8 8"
    
  >
    <path
      fill="currentColor"
      d="M3 0c-.55 0-1 .45-1 1v1H.09C.03 2 0 2.04 0 2.09V4.5c0 .28.22.5.5.5h7c.28 0 .5-.22.5-.5V2.09C8 2.03 7.96 2 7.91 2H6V1c0-.55-.45-1-1-1H3zm0 1h2v1H3V1zM0 5.91v2c0 .05.04.09.09.09H7.9c.05 0 .09-.04.09-.09v-2c-.16.06-.32.09-.5.09h-7c-.18 0-.34-.04-.5-.09z"
    ></path>
  </svg>
);

export const HandshakeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
  >
    <path
      fill="currentColor"
      d="M13 3a5.393 5.393 0 0 1-1.902 1.178c-.748.132-2.818-.828-3.838.152c-.17.17-.38.34-.6.51c-.48-.21-1.22-.53-1.76-.84S3 3 3 3L0 6.5s.74 1 1.2 1.66c.3.44.67 1.11.91 1.56l-.34.4a.876.876 0 0 0 .15 1a.833.833 0 0 0 1.002-.002a.62.62 0 0 0 .077.881a.994.994 0 0 0 1.006-.002a.806.806 0 0 0-.003 1.005a1.012 1.012 0 0 0 .892-.114a.822.822 0 0 0 .187.912a1.093 1.093 0 0 0 1.054-.092l.516-.467c.472.47 1.123.761 1.842.761l.061-.001a1.311 1.311 0 0 0 1.094-.791c.146.056.312.094.488.094c.236 0 .455-.068.64-.185c.585-.387.445-.687.445-.687a1.07 1.07 0 0 0 1.229-.279a.996.996 0 0 0 .138-1.215a.036.036 0 0 0 .021.005c.421 0 .787-.232.978-.574a1.564 1.564 0 0 0-.191-1.48l.003.005c.82-.16.79-.57 1.19-1.17a4.725 4.725 0 0 1 1.387-1.208zm-.05 7.06c-.44.44-.78.25-1.53-.32S9.18 8.1 9.18 8.1c.061.305.202.57.401.781c.319.359 1.269 1.179 1.719 1.599c.28.26 1 .78.58 1.18s-.75 0-1.44-.56s-2.23-1.94-2.23-1.94a.937.937 0 0 0 .27.72c.17.2 1.12 1.12 1.52 1.54s.75.67.41 1s-1.03-.19-1.41-.58c-.59-.57-1.76-1.63-1.76-1.63l-.001.053c0 .284.098.544.263.75c.288.378.848.868 1.188 1.248s.54.7 0 1s-1.34-.44-1.69-.8v-.002a.411.411 0 0 0-.1-.269a.896.896 0 0 0-.906-.188A.609.609 0 0 0 6 11.1a.754.754 0 0 0-.912.001a.61.61 0 0 0-.085-.95a1 1 0 0 0-1.174.08a.66.66 0 0 0-.068-.911a.996.996 0 0 0-1.186-.128L1.91 8.069c-.46-.73-1-1.49-1-1.49l2.28-2.77s.81.5 1.48.88c.33.19.9.44 1.33.64c-.68.51-1.25 1-1.08 1.34a1.834 1.834 0 0 0 2.087.036a2.41 2.41 0 0 1 1.343-.403c.347 0 .677.072.976.203c.554.374 1.574 1.294 2.504 1.874c1.17.85 1.4 1.4 1.12 1.68z"
    ></path>
  </svg>
);

export function Home() {
  return (
    <>
      <div className="App">
        <TornadoScene />
        <div id="hero2_section">
          <h1>Building disruptive software products for the Internet</h1>
          <div className="hero2_section_content">
            <p>
              We envision <PaletteLtrIcon />, create, nurture <ChildIcon />, and
              drive the success of <u>our own ideas</u> <LightBulbFillIcon /> 
            </p><p>
              As an
              independent, multi-disciplinary software investment firm{" "}
              <BankIcon />, we recognize the importance of fueling every single
              venture <Fan /> with a commitment to experimentation <HatWizardIcon />,
              strategic insight, and collaboration. 
              <br/> 
            </p>
            <p>
              <OutlineInsightsIcon />{" "} Partnering from the earliest stages, we provide our portfolio
              companies <AppleShortcutsSolidIcon /> with resources, capital,
              talent <BriefcaseIcon />, and expertise. <BrainIcon /> Our
              hands-on, people-first <HandshakeIcon /> approach drives our
              success <ChartUpFillIcon />, guided by a deep understanding of
              user behavior and market cycles. <SharpTouchAppIcon />
            </p>
            <p className="hero2_p_content">
              {" "}
              Simply put, we deploy profitable ideas—fast.
            </p>

            <img src="./szn.webp" className="hero2_img" />

            <Link className="grey-button" to="/about/agencies">
              EXPLORE THE ECOSYSTEM
              <div className="arrow_inside_button w-embed">
                <svg
                  width="15px"
                  height="15px"
                  viewBox="0 0 24 24"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-labelledby="arrowRightTopIconTitle"
                  stroke="#000000"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  fill="none"
                  color="#000000"
                >
                  {" "}
                  <title id="arrowRightTopIconTitle">
                    Arrow Right Top
                  </title>{" "}
                  <path d="M19 13V5h-8" />{" "}
                  <path strokeLinecap="round" d="M19 5l-1 1" />{" "}
                  <path d="M18 6L5 19" />{" "}
                </svg>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default function App() {
  console.log('Rendering Helmet');
  return (
    <>
    <Helmet>
        {/* Standard SEO Meta Tags */}
        <title>sznlabs.com · home</title>
        <meta name="description" content="SZN LABS is a global, independent, multidisciplinary software and investment firm leveraging experimentation, strategic insight, and collaboration to solve the most intriguing challenges on the Internet. Explore our work, teams, careers, and more." /> 
        <meta name="keywords" content="creative agency, film production, digital media, entertainment company, content creation, brand development, independent films, media production, talent management, influencer marketing, visual storytelling, documentary production, cinematic storytelling, creative direction, film distribution, multimedia projects, creative consultancy, entertainment branding, original content, content strategy, creative marketing, software investment, venture capital, tech startups, software funding, software ventures, tech investment, digital innovation, SaaS funding, technology investment firm, software growth capital, software portfolio management, software mergers and acquisitions, software equity, software venture capital firm, tech investment firm, software scaling, IT investments, software angel investors, seed funding software, private equity software investments" />
        <meta name="author" content="SZN LABS" />
        <meta name="copyright" content="Copyright 2024, SZN LABS. All rights reserved" />
        
        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="SZN LABS" />
        <meta property="og:description" content=" SZN LABS is a global, independent multidisciplinary software and investment firm leveraging experimentation, strategic insight, and collaboration to solve the most intriguing challenges on the Internet. Explore our work, teams, careers, and more." />
        <meta property="og:image" content="./assets/image/share/index.jpg" />
        <meta property="og:url" content="https://sznlabs.com" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="SZN LABS" />
        <meta property="og:locale" content="en_US" />

        {/* Twitter Meta Tags */}
        <meta name="twitter:title" content="SZN LABS" />
        <meta name="twitter:description" content="SZN LABS is a global, independent multidisciplinary software and investment firm leveraging experimentation, strategic insight, and collaboration to solve the most intriguing challenges on the Internet. Explore our work, teams, careers, and more." />
        <meta name="twitter:image" content="./assets/images/share/index.jpg" />
        <meta name="twitter:site" content="@sznlabs" />
        <meta name="twitter:card" content="summary_large_image" />

        {/* Favicons and Apple Touch Icons */}
        <link rel="apple-touch-icon" sizes="57x57" href="./assets/images/fav/apple-icon-57x57.png" />
        <link rel="apple-touch-icon" sizes="60x60" href="./assets/images/fav/apple-icon-60x60.png" />
        <link rel="apple-touch-icon" sizes="72x72" href="./assets/images/fav/apple-icon-72x72.png" />
        <link rel="apple-touch-icon" sizes="76x76" href="./assets/images/fav/apple-icon-76x76.png" />
        <link rel="apple-touch-icon" sizes="114x114" href="./assets/images/fav/apple-icon-114x114.png" />
        <link rel="apple-touch-icon" sizes="120x120" href="./assets/images/fav/apple-icon-120x120.png" />
        <link rel="apple-touch-icon" sizes="144x144" href="./assets/images/fav/apple-icon-144x144.png" />
        <link rel="apple-touch-icon" sizes="152x152" href="./assets/images/fav/apple-icon-152x152.png" />
        <link rel="apple-touch-icon" sizes="180x180" href="./assets/images/fav/apple-icon-180x180.png" />

        <link rel="icon" type="image/png" sizes="192x192" href="./assets/images/fav/android-icon-192x192.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="./assets/images/fav/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="./assets/images/fav/favicon-96x96.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="./assets/images/fav/favicon-16x16.png" />

        {/* MS Application and other Meta Tags */}
        <meta name="msapplication-TileImage" content="./assets/images/fav/ms-icon-144x144.png" />
        <meta name="msapplication-TileColor" content="#000000" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        <meta name="theme-color" content="#33e333" />

        {/* Canonical Link */}
        <link rel="canonical" href="https://sznlabs.com" />

        {/* Manifest for Progressive Web Apps */}
        <link rel="manifest" href="./assets/manifest.json" />

    </Helmet>
      <Routes>
        <Route path="/" element={<Home />} />

        {/**

 <Route path="/about/vision" element={<About />} />
        <Route path="/voyages" element={<Voyages />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/press" element={<Press />} />
        <Route path="/support" element={<Support />} />

        **/}
        {/* Catch-all route for undefined paths */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}
